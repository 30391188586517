import { CancelToken } from 'axios';
import { Controller, ControllerInterface } from 'store/selectedVessel/types';
import { Condition, Routine } from 'store/vesselRoutines/types';
import { CameraDTO } from 'types/security';

import { MyResponse, RequestConfig, requestMiddleware } from './request';

export type AlertPaginatedType = {
  page: number;
  size: number;
  search: string;
  sortColumn: string;
  sortDesc?: boolean;
  dateFrom: string;
  dateTo: string;
  timeFrame: string | number | null;
  status: string | null;
};

export async function getVesselForId(id: string, cancelToken?: CancelToken): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}`,
    method: 'GET',
    cancelToken,
  };
  return requestMiddleware(config);
}

export async function getVesselDevices(id: string, cancelToken?: CancelToken): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}/devices`,
    method: 'GET',
    cancelToken,
  };
  return requestMiddleware(config);
}

export async function getVesselDevicesBasic(
  id: string,
  cancelToken?: CancelToken
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}/devices/basic`,
    method: 'GET',
    cancelToken,
  };
  return requestMiddleware(config);
}

export async function getDeviceAlertsLogs(deviceId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/${deviceId}/alertLogs`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getDeviceAlertsLogsPaginated(
  deviceId: string,
  data: AlertPaginatedType
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/${deviceId}/alertLogs/page`,
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function getDeviceAlertLog(
  deviceId: string,
  payload: {
    logId: string;
    note: string;
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/${deviceId}/alertLogs`,
    method: 'POST',
    data: payload,
  };
  return requestMiddleware(config);
}

export async function getVesselAlerts(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function ackAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/${alertId}/ack`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function silenceAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/${alertId}/silence`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getVesselRoutines(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/routines`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getIsVesselOnline(vesselId: string | null): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/vesselOnline?vesselId=${vesselId}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselDirectory(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/directory`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselNotifyDirectory(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/geofenceNotify/permissions`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselDocuments(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/documents`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselPermissions(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/permissions`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getControllers(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/interfaces`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getInterfaceTemplate(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/appSettings/empty`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getInterfaces(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/appSettings`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postNewInterface(
  vesselId: string,
  data: ControllerInterface
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/appSettings`,
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function putUpdateInterface(
  vesselId: string,
  interfaceId: string,
  data: ControllerInterface
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/appSettings/${interfaceId}`,
    method: 'PUT',
    data: data,
  };
  return requestMiddleware(config);
}

export async function deleteInterfaceObject(
  vesselId: string,
  interfaceId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/appSettings/${interfaceId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function postUploadInterfaceImage(
  vesselId: string,
  interfaceId: string,
  InterfaceImage: FormData,
  darkMode: boolean,
  portrait: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/appSettings/${interfaceId}/setImage?darkMode=${darkMode}&portrait=${portrait}`,
    method: 'POST',
    data: InterfaceImage,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  return requestMiddleware(config);
}

export async function getRevealToken(vesselId: string, controllerId: number): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/interfaces/${controllerId}/token`,
    method: 'GET',
  });
}

export async function getVesselWeather(lat: string, lon: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&units=metric&appid=${process.env.REACT_APP_OPEN_WEATHER_MAP_APP_ID}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselAudit(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}/logs/all`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export interface GetVesselAuditPaginatedInterface {
  page: number;
  size: number;
  search: string;
  sortColumn: string;
  sortDesc: boolean;
  dateFrom: string;
  dateTo: string;
  messageType: any;
}

export async function getVesselAuditPaginated(
  data: GetVesselAuditPaginatedInterface,
  vesselId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/logs/all/page`,
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function getVesselNotes(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}/notes`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselBusList(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${id}/busList`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getBusProperties(id: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/monitoredDevice/busProperties?bus=${id}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postVesselNote(
  messageText: string,
  vesselId: string | number
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/notes`,
    method: 'POST',
    data: {
      messageText,
    },
  };
  return requestMiddleware(config);
}

export async function deleteVesselNote(
  noteId: string | number,
  vesselId: string | number
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/notes/${noteId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function postSetUserNotificationsForVessel(
  vesselId: string,
  state: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/userNotifications/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSetVesselPropertyUpdateEnable(
  vesselId: string,
  state: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/propertyUpdate/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSetGpsReporting(vesselId: string, state: boolean): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/gpsReporting/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postSetMfrAccess(vesselId: string, state: boolean): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/mfrAccess/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getDiscoveryBuses(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/discovery/messageSources`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function startDiscoveryByBuses(
  vesselId: string,
  data: {
    messageSources: string[];
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/discovery/messageSources`,
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postSendVesselCommand(
  deviceProperty: number,
  value: number | string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: '/api/vessel/sendCommand',
    method: 'POST',
    data: {
      deviceProperty,
      value,
    },
  };
  return requestMiddleware(config);
}

export async function getVesselUserNotificationStatus(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/userNotifications`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVesselSubscription(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/subscription`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postUploadVesselDocument(
  formData: any,
  vesselId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/documents`,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  };
  return requestMiddleware(config);
}

export async function deleteVesselDocument(
  vesselId: string,
  downloadId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/documents/${downloadId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function pauseVesselRoutine(
  vesselId: string,
  routineId: string,
  state: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/routines/${routineId}/pause/${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function deleteVesselRoutine(
  vesselId: string,
  routineId: string
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/routines/${routineId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function postTestConditions(
  vesselId: string,
  matchAllConditions: boolean,
  conditions: Condition[]
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/routines/testConditions?matchAll=${matchAllConditions}`,
    method: 'POST',
    data: conditions,
  };
  return requestMiddleware(config);
}

export async function postRequestVesselAccess(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/requestAccess`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function postDeleteVessel(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/delete?vesselId=${vesselId}`,
    method: 'POST',
  });
}
export async function postConfirmDeleteVessel(code: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/confirmDelete?code=${code}`,
    method: 'POST',
  });
}

export async function postFlipRoutine(data: {
  routineId: string;
  alert: boolean;
}): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/routine/${data.routineId}/flip/${data.alert}`,
    method: 'POST',
  });
}

export async function getVesselProducts(vesselId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/products`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function getVoucherDetails(code: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/profile/billing/showVoucherDetails?code=${code}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function postCreateVesselSubscription(data: {
  vesselId: string;
  productIds: number[];
  paymentInterval: string;
  paymentMethodId: number;
}): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/billing/createVesselSubscription',
    method: 'POST',
    data: data,
  });
}

export async function postChangeVesselSubscription(data: {
  subscriptionId: number;
  productIds: number[];
  paymentInterval: string;
}): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/billing/changeSubscriptionProducts',
    method: 'POST',
    data: data,
  });
}

export async function postCreateVesselSubscriptionWithVoucher(data: any): Promise<MyResponse> {
  return requestMiddleware({
    url: '/api/profile/billing/createVesselSubscriptionWithVoucher',
    method: 'POST',
    data: data,
  });
}

export async function postUnlinkClient(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/unlinkClient`,
    method: 'POST',
  });
}

export async function postCompleteClientUnlink(code: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/completeClientUnlink?code=${code}`,
    method: 'POST',
  });
}

export async function postCreateRoutine2(vesselId: string, data: Routine): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/routines/create`,
    method: 'POST',
    data: data,
  };
  return requestMiddleware(config);
}

export async function postCreateCustomAlert(
  vesselId: string,
  payload: {
    name: string;
    message: string;
    alertLevel: string;
    notifyUsers: string[];
    matchAllConditions: boolean;
    conditions: Condition[];
    repeatOptions: string;
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/customAlerts`,
    method: 'POST',
    data: payload,
  };
  return requestMiddleware(config);
}

export async function putUpdateCustomAlert(
  vesselId: string,
  alertId: string,
  payload: {
    name: string;
    message: string;
    alertLevel: string;
    notifyUsers: string[];
    matchAllConditions: boolean;
    conditions: Condition[];
    repeatOptions: string;
  }
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/customAlerts/${alertId}`,
    method: 'PUT',
    data: payload,
  };
  return requestMiddleware(config);
}

export async function getEditCustomAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/customAlerts/${alertId}`,
    method: 'GET',
  };
  return requestMiddleware(config);
}

export async function deleteCustomAlert(vesselId: string, alertId: string): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/customAlerts/${alertId}`,
    method: 'DELETE',
  };
  return requestMiddleware(config);
}

export async function pauseVesselAlert(
  vesselId: string,
  alertId: string,
  state: boolean
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/customAlerts/${alertId}/pause?paused=${state}`,
    method: 'POST',
  };
  return requestMiddleware(config);
}

export async function getAlertsLogsPaginated(
  vesselId: string,
  data: AlertPaginatedType
): Promise<MyResponse> {
  const config: RequestConfig = {
    url: `/api/vessel/${vesselId}/alerts/history/page`,
    method: 'POST',
    data,
  };
  return requestMiddleware(config);
}

export async function getVesselLocationHistory(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/locationHistory`,
    method: 'GET',
  });
}

export async function getDocumentStorage(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/documentStorage`,
    method: 'GET',
  });
}

export async function getVesselGeofenceParams(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/geofence`,
    method: 'GET',
  });
}

export async function postSetGeoFenceState(
  vesselId: string,
  enabled: boolean
): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/geofence/${enabled}`,
    method: 'POST',
  });
}

export async function postSetGeoFenceParams(
  vesselId: string,
  radius: number,
  lat: string,
  lon: string,
  delay: number
): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/geofence`,
    method: 'POST',
    data: { radius, lat, lon, delay },
  });
}

export async function postSetGeofenceNotificationPref(
  vesselId: string,
  permissionId: string,
  enabled: boolean
): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/geofenceNotify/${permissionId}/${enabled}`,
    method: 'POST',
  });
}

export async function getCameras(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/cameras`,
    method: 'GET',
  });
}

export async function postAddCamera(vesselId: string, data: CameraDTO): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/cameras`,
    method: 'POST',
    data: data,
  });
}

export async function putEditCamera(
  vesselId: string,
  id: number,
  data: CameraDTO
): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/cameras/${id}`,
    method: 'PUT',
    data: data,
  });
}

export async function deleteCamera(vesselId: string, id: number): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/cameras/${id}`,
    method: 'DELETE',
  });
}

export async function postNewController(vesselId: string, data: Controller): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/interfaces/new`,
    method: 'POST',
    data: data,
  });
}

export async function putUpdateController(
  vesselId: string,
  interfaceId: number,
  data: Controller
): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/interfaces/${interfaceId}`,
    method: 'PUT',
    data: data,
  });
}

export async function deleteController(vesselId: string, id: number): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/interfaces/${id}`,
    method: 'DELETE',
  });
}

export async function getUserPerms(vesselId: string): Promise<MyResponse> {
  return requestMiddleware({
    url: `/api/vessel/${vesselId}/currentUserPerms`,
    method: 'GET',
  });
}
